import React from 'react'
import banner from '../assets/images/banner.png';

const HeaderGeneric = (props) => (
    <header id="header" className="alt">
        <a href="/">
            <span className="logo">
                <img src={banner} alt="" /> 
                <div className="title">STAKENODE.ONE</div>
                <div className="description">Staking With Us</div>
            </span>
        </a>
    </header>
)

export default HeaderGeneric
